/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useDisconnect, useSignMessage, useConnect, useAccount } from "wagmi";
import { Button, Image, SVG } from "~components";

import { genericErrorCallback, walletAddressAreEqual, DEFAULT_CULTURE, TARGET_CHAIN } from "~utils/helpers";
import { useApp, useKeyPress } from "~hooks";
import walletconnect from "~assets/images/walletconnect.png";
import { breakpoint } from "~utils/css";
import loader from "~assets/images/loader.webp";
import metamask from "~assets/images/metamask.png";
import google from "~assets/images/google.svg";
import discord from "~assets/images/discord.svg";
import x from "~assets/images/x.svg";
import apple from "~assets/images/apple.svg";
import altrLoginAPI from "~hooks/altrLoginApi";
import SIWEService from "~dApp/SIWEService";
import LoginService from "~services/auth";
import TermsAndConditionsService from "~services/termsAndConditions";
import { AuthenticationWalletCredential } from "~dApp/models/ApiModel";
import ConnectOverlayWalletButton from "./ConnectOverlayWalletButton";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  transition:
    opacity 0.3s var(--cubic-easing),
    transform 0.3s var(--cubic-easing);

  opacity: ${({ active }) => `${active ? 1 : 0}`};

  ${({ active }) => {
    if (active) {
      return css`
        pointer-events: auto;
      `;
    }
    return css`
      pointer-events: none;
      * {
        pointer-events: none !important;
      }
    `;
  }};

  transform: scale(${({ active }) => `${active ? 1 : 1.05}`});

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;

  .connectOverlay {
    &Subheading {
      color: #919191;
      margin-bottom: 5px;
    }

    &AcceptButton {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition-property: none;
      ${({ isLoading }) =>
        `${isLoading ? `background-image: url(${loader}); background-color: transparent; color: transparent; border: 1px solid black;` : ``}`};
      &:hover {
        ${({ isLoading }) => `${isLoading ? `background-color: transparent; color: transparent; border: 1px solid black;` : ``}`};
      }
      &Text {
        ${({ isLoading }) => `${isLoading ? `color: transparent;` : ``}`};
      }
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background: black;
`;

const LoadingAnimation = styled.div`
  transition: opacity 0.3s var(--cubic-easing);

  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`;

//
// Connectors

const Connectors = styled.article`
  width: calc(100% - 2rem);
  position: relative;
  margin: 0 auto;
  z-index: 10;
  padding: 20px 24px 40px;
  color: var(--color-white);
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.35);
  border-radius: 16px;
  overflow-y: ${({ prosAndCons }) => (prosAndCons ? `scroll` : `hidden`)};
  background: #161616;
  max-height: 90vh;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpoint(`large-tablet`)} {
    width: 426px;
  }
`;
const CloseButton = styled.button`
  position: relative;
  z-index: 30;
`;
const CloseButtonInner = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
  z-index: 30;
`;
const ConnectorsHeader = styled.header`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ position }) => (position === `left` ? `flex-start` : `flex-end`)};
`;
const ConnectorsHeading = styled.h2`
  margin-bottom: 8px;
`;
const ConnectorList = styled.ul`
  width: 100%;
  position: relative;
  margin-top: 1.5rem;
`;
const SocialLoginConnectors = styled.li`
  display: grid;
  grid-gap: 15px;

  .socialLoginButton {
    --background-color: #282828;
    --border-color: #282828;
    --hover-background-color: #535353;
    --hover-border-color: #535353;
    --text-color: white;

    border-radius: 1rem;
    padding: 1rem 0;

    &Text {
      margin-left: 11px;
    }
  }

  p {
    grid-column: 1 / 4;
  }
`;
const ProsAndConsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  span {
    text-transform: none;
  }
`;
//
// Terms

const Terms = styled.div`
  width: calc(100% - 2rem);
  max-height: calc(100vh - 6rem);
  position: relative;
  margin: 0 auto;
  z-index: 10;
  background: var(--color-mono-10);
  padding: 20px 24px 40px;
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.35);
  border-radius: 16px;

  ${breakpoint(`large-tablet`)} {
    width: 878px;
    max-height: calc(100vh - 12rem);
  }
`;
const TermsText = styled.div`
  width: 100%;
  height: calc(70vh - 12rem);
  // max-height: 492px;
  position: relative;
  z-index: 10;
  overflow-y: scroll;
  margin-top: 1rem;
  padding: 30px;
  border-radius: 8px;
  background: var(--color-mono-20);
`;

//
// Pros and Cons
const ProsAndCons = styled.div`
  padding: 0 12px;
  color: white;
`;
const ProsAndConsHeading = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.24px;
  margin: 20px 0;
`;
const ProsAndConsList = styled.ul`
  list-style: disc;
`;
const ProsAndConsListElement = styled.li`
  font-size: 16px;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.16px;
  margin-left: 20px;
`;
/** ============================================================================
 * @component
 */
const ConnectOverlay = () => {
  const INVALID_NONCE = `invalid`;
  const preferredCulture = DEFAULT_CULTURE;

  // ---------------------------------------------------------------------------
  // wagmi

  const { connect, connectors } = useConnect();
  const { disconnect: walletDisconnect } = useDisconnect();
  const { connector } = useAccount();
  // ---------------------------------------------------------------------------
  // context

  // services
  const loginService = new LoginService();
  const siweService = new SIWEService();
  const termsAndConditionsService = new TermsAndConditionsService();

  // logins
  const { getNonceForOnboardedUser, walletAuthentication, useIsAValidLoginToken, updateUserSession } = altrLoginAPI();

  const { signMessageAsync, isError: isSigningError } = useSignMessage();

  // general
  const { connectorActive, setConnectorActive, userIsLoggedIn, setUserIsLoggedIn, userData } = useApp();
  const escKeyPressed = useKeyPress(`Escape`);
  useIsAValidLoginToken(setUserIsLoggedIn);

  // state
  const [nonceState, setNonceState] = useState(INVALID_NONCE);
  const [signedMessageState, setSignedMessageState] = useState(``);
  const [loadingData, setLoadingData] = useState(false);
  const [platformTermsAndConditionsState, setPlatformTermsAndConditionsState] = useState(null);
  const [platformTermsAndConditionsTextState, setPlatformTermsAndConditionsTextState] = useState(``);
  const [canSign, setCanSign] = useState(false);
  const [prosAndCons, setProsAndCons] = useState(false);
  const [loginError, setLoginError] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const disconnectUser = () => {
    loginService.logout();

    setNonceState(INVALID_NONCE);
    setSignedMessageState(``);
    setPlatformTermsAndConditionsState(null);
    setPlatformTermsAndConditionsTextState(``);
    setUserIsLoggedIn(false);
  };

  const disconnectAll = () => {
    walletDisconnect();
    disconnectUser();
  };

  const doConnect = (currentConnector) => {
    if (userData?.isConnected) {
      disconnectAll();
    }
    connect({
      connector: currentConnector,
      chainId: TARGET_CHAIN.id
    });
    if (currentConnector.id === `walletConnect`) {
      setConnectorActive(false);
    }
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setConnectorActive(false);
    setLoginError(false);
  }, [escKeyPressed]);

  useEffect(() => {
    if (isSigningError) {
      disconnectAll();
    }
  }, [isSigningError]);

  useEffect(() => {
    const addressIsTheSameOfLoggedUser = walletAddressAreEqual(userData?.address, userData?.user?.walletAddress);

    if (userData?.user && (!addressIsTheSameOfLoggedUser || !userData?.address)) {
      disconnectUser();
    }

    if (!userData?.address || (addressIsTheSameOfLoggedUser && (nonceState !== INVALID_NONCE || userData?.user?.token))) {
      return;
    }

    console.debug(`[debug] Reading Nonce`);

    // FIXME: use the correct preferred culture
    getNonceForOnboardedUser(userData.address, preferredCulture, setNonceState).catch((e) => console.error(e));
  }, [userData?.address]);

  useEffect(() => {
    if (!nonceState || nonceState === INVALID_NONCE || userIsLoggedIn || userData?.user?.token) {
      return;
    }

    const signMessage = async () => {
      if (!userData?.address) {
        return;
      }
      if (!userIsLoggedIn && connector?.id === `web3auth`) {
        setConnectorActive(true);
      }
      setLoadingData(true);

      let platformTermsAndConditions;

      try {
        platformTermsAndConditions = await termsAndConditionsService.getMarketplaceTermsAndConditions(userData?.address);
      } catch (error) {
        platformTermsAndConditions.alreadyAcceptedByUser = true;
      }

      if (!platformTermsAndConditions.alreadyAcceptedByUser) {
        let termsAndConditionsText = ``;
        try {
          termsAndConditionsText = await termsAndConditionsService.getTermsAndConditionsContent(
            platformTermsAndConditions.lastVersion,
            platformTermsAndConditions.lastIpldHash
          );
        } catch {
          setLoadingData(false);
          setLoginError(true);
          return;
        }
        setPlatformTermsAndConditionsTextState(termsAndConditionsText);
        if (!connectorActive) {
          setConnectorActive(true);
        }
      }

      const message = siweService.getLoginMessage(platformTermsAndConditions, nonceState, userData.address);

      setLoadingData(false);
      if (!platformTermsAndConditions.alreadyAcceptedByUser && !canSign && connector.id === `web3auth`) {
        return;
      }
      const signedMessage = await signMessageAsync({ message });
      setCanSign(false);

      setPlatformTermsAndConditionsState(platformTermsAndConditions);
      setSignedMessageState(signedMessage);
    };

    signMessage().catch(() => {});
  }, [nonceState, userIsLoggedIn, userData?.address, canSign]);

  useEffect(() => {
    if (!signedMessageState) {
      return;
    }

    console.debug(`[debug] Signed Message ${signedMessageState}`);

    const model = new AuthenticationWalletCredential();
    model.nonce = nonceState;
    model.baseMessage = siweService.getLoginBaseMessage(platformTermsAndConditionsState, userData.address);
    model.signedMessage = signedMessageState;
    model.publicAddress = userData?.address;
    model.preferredCulture = preferredCulture;

    const acceptedPlatformTermsAndConditionsVM = termsAndConditionsService.buildAcceptedTermsAndConditions(platformTermsAndConditionsState, model);

    const userAuthentication = async () => {
      const authenticationData = await walletAuthentication(model);

      const sessionData = await updateUserSession(authenticationData.token, acceptedPlatformTermsAndConditionsVM);

      loginService.walletLoginCallback(authenticationData, sessionData, preferredCulture);

      setUserIsLoggedIn(true);
      setConnectorActive(false);
    };

    userAuthentication().catch(genericErrorCallback);
  }, [signedMessageState, platformTermsAndConditionsState]);

  // ---------------------------------------------------------------------------
  // render

  let activePane;
  if (prosAndCons) {
    activePane = `prosAndCons`;
  } else if (userData?.isConnected && !userData?.user && !userIsLoggedIn && platformTermsAndConditionsTextState !== ``) {
    activePane = `terms`;
  } else if (userData?.isConnected && userData?.user && userIsLoggedIn) {
    activePane = `manage`;
  } else {
    activePane = `connect`;
  }

  return (
    <Container active={connectorActive} isLoading={!!loadingData}>
      <Background />

      {activePane !== `terms` && (
        <Connectors prosAndCons={prosAndCons}>
          <LoadingAnimation active={loadingData || loginError}>
            {loadingData && (
              <Image
                css={css`
                  width: 50%;
                `}
                image={loader}
              />
            )}
            {!loadingData && loginError && <div style={{ color: `black` }}>An error occurred please try again later.</div>}
          </LoadingAnimation>

          <ConnectorsHeader position={prosAndCons ? `left` : `right`}>
            <CloseButton
              type="button"
              onClick={() => {
                if (prosAndCons) {
                  setProsAndCons(false);
                } else {
                  setLoginError(false);
                  setConnectorActive(false);
                }
              }}
            >
              {prosAndCons ? (
                <Button fluid variant="text" iconLeft="arrowLeft">
                  <span className="caption">Back to login</span>
                </Button>
              ) : (
                <>
                  <CloseButtonInner>
                    <SVG
                      svg="cross"
                      css={css`
                        width: 16px;
                      `}
                      fill={loadingData || loginError ? `black` : `white`}
                    />
                  </CloseButtonInner>
                  <span className="visually-hidden">Close</span>
                </>
              )}
            </CloseButton>
          </ConnectorsHeader>

          {activePane === `manage` ? (
            <>
              <ConnectorsHeading className="h3">Manage Wallet</ConnectorsHeading>

              {userData?.address && (
                <>
                  <p
                    css={css`
                      margin-top: 1rem;
                      color: var(--color-mono-40);
                    `}
                    className="b2"
                  >
                    Wallet address:
                  </p>
                  <p
                    css={css`
                      color: var(--color-white);
                    `}
                    className="b2"
                  >
                    {userData.address}
                  </p>
                </>
              )}

              <Button
                css={css`
                  margin-top: 1.5rem;
                `}
                color="primary-orange-100"
                onClick={() => disconnectAll()}
                full
                large
                title="Sign Out"
              >
                <span className="button-text">Sign Out</span>
              </Button>
            </>
          ) : activePane === `prosAndCons` ? (
            <ProsAndCons>
              <ProsAndConsHeading>Pros of logging in with your digital wallet:</ProsAndConsHeading>
              <ProsAndConsList>
                <ProsAndConsListElement>
                  More secure: Your digital wallet is typically more secure than your social media account, as it uses stronger encryption and authentication
                  methods.
                </ProsAndConsListElement>
                <ProsAndConsListElement>
                  More private: Your digital wallet data is not shared with the website or app you are logging into, unlike with social login.
                </ProsAndConsListElement>
                <ProsAndConsListElement>
                  More convenient: You can log in to websites and apps with just a few taps on your phone, without having to remember multiple usernames and
                  passwords.
                </ProsAndConsListElement>
              </ProsAndConsList>

              <ProsAndConsHeading>Cons of logging in with your digital wallet:</ProsAndConsHeading>
              <ProsAndConsList>
                <ProsAndConsListElement>Not as widely supported: Not all websites and apps support login with digital wallets.</ProsAndConsListElement>
                <ProsAndConsListElement>
                  Can be more difficult to set up: You may need to create a new account with your digital wallet provider.
                </ProsAndConsListElement>
              </ProsAndConsList>

              <ProsAndConsHeading>Pros of logging in with social login:</ProsAndConsHeading>
              <ProsAndConsList>
                <ProsAndConsListElement>Widely supported: Most websites and apps support login with social media accounts.</ProsAndConsListElement>
                <ProsAndConsListElement>Easy to set up: You can log in with your existing social media account.</ProsAndConsListElement>
              </ProsAndConsList>

              <ProsAndConsHeading>Cons of logging in with social login:</ProsAndConsHeading>
              <ProsAndConsList>
                <ProsAndConsListElement>
                  Less secure: Your social media account may not be as secure as your digital wallet, and your data could be more easily compromised.
                </ProsAndConsListElement>
                <ProsAndConsListElement>
                  Less private: Your social media data may be shared with the website or app you are logging into.
                </ProsAndConsListElement>
              </ProsAndConsList>
            </ProsAndCons>
          ) : (
            <>
              <ConnectorsHeading className="h3">Login</ConnectorsHeading>

              <p className="b2 connectOverlaySubheading">Connect to Altr with one of these wallets</p>

              <ConnectorList>
                <ConnectOverlayWalletButton key={connectors[0].id} connector={connectors[0]} image={metamask} onClick={() => doConnect(connectors[0])} />
                <ConnectOverlayWalletButton key={connectors[1].id} connector={connectors[1]} image={walletconnect} onClick={() => doConnect(connectors[1])} />
                <SocialLoginConnectors>
                  <p className="b2 connectOverlaySubheading">Or by using your social account</p>
                  <Button className="socialLoginButton" fluid style={{ gridColumn: `1 / 4` }} onClick={() => doConnect(connectors[2])}>
                    <Image image={google} />
                    <span className="socialLoginButtonText">Continue with Google</span>
                  </Button>
                  <Button className="socialLoginButton" fluid style={{ gridColumn: `1 / 2` }} onClick={() => doConnect(connectors[3])}>
                    <Image image={apple} />
                    <span className="visually-hidden">Continue with AppleID</span>
                  </Button>
                  <Button className="socialLoginButton" fluid style={{ gridColumn: `2 / 3` }} onClick={() => doConnect(connectors[4])}>
                    <Image image={x} />
                    <span className="visually-hidden">Continue with X</span>
                  </Button>
                  <Button className="socialLoginButton" fluid style={{ gridColumn: `3 / 4` }} onClick={() => doConnect(connectors[5])}>
                    <Image image={discord} />
                    <span className="visually-hidden">Continue with Discord</span>
                  </Button>
                </SocialLoginConnectors>
              </ConnectorList>

              <ProsAndConsButtonContainer>
                <Button className="underline caption" variant="text" onClick={() => setProsAndCons(true)}>
                  Pros & Cons of every login option
                </Button>
              </ProsAndConsButtonContainer>
            </>
          )}
        </Connectors>
      )}

      {activePane === `terms` && (
        <Terms>
          <ConnectorsHeader>
            <CloseButton
              type="button"
              onClick={() => {
                disconnectAll();
                setConnectorActive(false);
                setLoginError(false);
              }}
            >
              <CloseButtonInner>
                <SVG
                  svg="cross"
                  css={css`
                    width: 16px;
                  `}
                  fill="black"
                />
              </CloseButtonInner>
            </CloseButton>
          </ConnectorsHeader>

          <header>
            <ConnectorsHeading className="h3">Terms and Conditions</ConnectorsHeading>

            <p
              css={css`
                color: var(--color-mono-40);
              `}
              className="b2"
            >
              Agreement is necessary to proceed.
            </p>
          </header>

          <TermsText>
            <div
              className="caption"
              css={css`
                p {
                  margin-bottom: 1rem;
                }
              `}
              dangerouslySetInnerHTML={{
                __html: platformTermsAndConditionsTextState
              }}
            />
          </TermsText>

          {connector?.id !== `web3auth` ? (
            <p
              css={css`
                position: relative;
                display: block;
                margin-top: 1.5rem;
                text-align: center;
              `}
              className="b1"
            >
              Accept the terms with your wallet.
            </p>
          ) : (
            <Button
              css={css`
                position: relative;
                display: block;
                margin-top: 1.5rem;
                text-align: center;
              `}
              className="connectOverlayAcceptButton"
              variant="primary"
              colorTheme="dark"
              fluid
              disabled={loadingData}
              onClick={() => {
                setCanSign(true);
              }}
            >
              <span className="connectOverlayAcceptButtonText">Accept the terms</span>
            </Button>
          )}
        </Terms>
      )}
    </Container>
  );
};

export default ConnectOverlay;
